import { useEffect, useState } from "react";
import { InputSingle } from "../components/input";
import { Navbar } from "./components/navbar";
import { UserProps } from "../types/userProps";
import { api } from "../services/axios";
import { toast, ToastContainer } from "react-toastify";
import { ChevronRight, Loader } from "lucide-react";

export function AccountSettings() {
    const [isLoadingCode, setIsLoadingCode] = useState(false);
    const [dataUser, setDataUser] = useState<UserProps | null>(null);
    const [email, setEmail] = useState('');
    const [markUpFee, setMarkUpFee] = useState('');
    const [scretKey, setScretKey] = useState('');
    const [showMenu, setShowMenu] = useState(true);
    const getDataUser = JSON.parse(localStorage.getItem('@dataAffiliateCrypto') ?? '{}');

    useEffect(() => {
        if (getDataUser) {
            setDataUser(getDataUser);
            if (getDataUser?.user_email) {
                setEmail(getDataUser?.user_email);

                // Converte o markup fee para porcentagem
                let markupFeeValue = Number(getDataUser?.markupfee);

                // Verifica se o valor está no intervalo válido (entre 0 e 5)
                if (markupFeeValue > 0 && markupFeeValue <= 5) {
                    // Converte o valor para porcentagem
                    markupFeeValue = markupFeeValue * 100;

                    // Arredonda para 1 casa decimal e exibe como porcentagem
                    const markUpFeeAsPercent = `${markupFeeValue.toFixed(1)}%`;

                    // Define o estado com o valor formatado
                    setMarkUpFee(markUpFeeAsPercent);
                } else {
                    // Se o valor estiver fora do intervalo, limpa o campo
                    setMarkUpFee('');
                }
            }

            if (getDataUser?.affiliate_code) {
                setScretKey(getDataUser?.affiliate_code);
            }
        }
    }, [getDataUser?.markupfee]);

    const handleChangeMarkUpFee = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value.replace('%', '').replace(',', '.').trim();
    
        // Permite que o usuário apague o valor caractere por caractere sem adicionar "%".
        setMarkUpFee(inputValue.length ? `${inputValue}` : '');
    
        // Retorna se o valor está vazio, permitindo que o campo seja apagado.
        if (inputValue === '') {
            return;
        }
    
        // Adiciona "0." se o usuário digitar "0" e ainda não tiver um ponto decimal.
        if (inputValue === '0') {
            setMarkUpFee('0.');
            return;
        }
    
        // Aplica o ponto decimal automaticamente para números inteiros entre 1 e 5.
        if (/^[1-5]$/.test(inputValue)) {
            setMarkUpFee(`${inputValue}.`);
            return;
        }
    
        // Limita o número de dígitos decimais a 3 após o ponto, se houver.
        if (inputValue.includes('.')) {
            const [integerPart, decimalPart] = inputValue.split('.');
            if (decimalPart && decimalPart.length > 3) {
                inputValue = `${integerPart}.${decimalPart.slice(0, 3)}`;
            }
        }
    
        // Permite apenas valores de 0.0 até 5.0 e aplica a formatação final com "%".
        const numericValue = parseFloat(inputValue);
        if (!isNaN(numericValue) && numericValue <= 5) {
            setMarkUpFee(`${inputValue}%`);
        } else if (inputValue === '0.') {
            setMarkUpFee('0.%');
        } else {
            // Limpa o campo se o valor for inválido.
            setMarkUpFee('');
        }
    };

    const updateAffiliateData = async () => {
        setIsLoadingCode(true);
        try {
            // Converte o valor para decimal adequado ao enviar ao backend
            let markUpFeeFormatted = parseFloat(markUpFee.replace('%', '')) / 100;

            const response = await api.post('/v1/UpdateAffiliatefee', {
                affiliate_code: dataUser?.affiliate_code,
                new_fee: markUpFeeFormatted.toString(),
            });

            if (response) {
                const newDataStorage = {
                    ...getDataUser,
                    markupfee: markUpFeeFormatted.toString(),
                };
                localStorage.setItem('@dataAffiliateCrypto', JSON.stringify(newDataStorage));
                toast.success('Fee alterado com sucesso.');
            }
        } catch (error: any) {
            toast.error(error.response.data.detail);
            console.log(error);
        } finally {
            setIsLoadingCode(false);
        }
    };

    const handleShowMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div className="relative w-full h-screen flex overflow-hidden bg-zinc-700">
            <Navbar
                inicio={false}
                searUser={false}
                settings
                withdraw={false}
                documents={false}
                links={false}
                handleShowMenu={handleShowMenu}
                showMenu={showMenu}
            />
            <ToastContainer theme="dark" />

            <div className="w-full h-full p-6">
                <h2 className="text-slate-200 text-2xl mt-14 md:mt-0">Configurações</h2>

                <div className="flex flex-col w-full max-w-xl gap-3 mt-4 md:mt-8">
                    <div className="relative">
                        <InputSingle
                            disabled
                            type="text"
                            label="E-mail"
                            placeholder="johndoe@gmail.com"
                            styleComp="bg-white pr-10"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="relative">
                        <InputSingle
                            disabled
                            type="text"
                            label="Código afiliado"
                            placeholder={dataUser?.affiliate_code}
                            styleComp="bg-white pr-10"
                            value={scretKey}
                            onChange={(e) => setScretKey(e.target.value)}
                        />
                    </div>
                    <div className="relative">
                        <InputSingle
                            type="text"
                            label="Mark Up Fee"
                            placeholder=""
                            styleComp="bg-white pr-10"
                            value={markUpFee}
                            onChange={handleChangeMarkUpFee}
                        />
                        <button
                            className="absolute top-1/2 -translate-y-1/2 right-4 mt-[6px]"
                            onClick={updateAffiliateData}
                        >
                            {isLoadingCode ?
                                <Loader className="animate-spin text-yellow-500" />
                                : <button className="p-1 rounded-full bg-yellow-500">
                                    <ChevronRight color="white" size={18} />
                                </button>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
