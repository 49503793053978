import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import { ButtonNav } from "./buttonNav";
import { Menu, X } from "lucide-react";
import { useEffect, useState } from "react";

interface NavbarProps {
    inicio: boolean;
    searUser: boolean;
    settings: boolean;
    documents: boolean;
    links: boolean;
    withdraw: boolean;
    showMenu: boolean;
    handleShowMenu: () => void;
}

export function Navbar({
    inicio,
    searUser,
    settings,
    documents,
    links,
    withdraw,
    handleShowMenu,
    showMenu,
}: NavbarProps) {
    const [widthScreen, setWidthScreen] = useState(window.innerWidth);
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('@dataAffiliateCrypto');
        localStorage.removeItem('#affiliate-verified');
        sessionStorage.removeItem('#affiliate-verified');
        navigate('/affiliate');
    }

    useEffect(() => {
        if(widthScreen <= 768) {
            setWidthScreen(window.innerWidth);
            handleShowMenu();
        }
    }, [widthScreen]);

    return (
        <aside className={`md:relative absolute top-0 md:w-[434px] ${showMenu ? 'w-[300px h-full' : 'w-full h-12 overflow-y-hidden'} box-border p-8 bg-sidebar border-r border-slate-700/50 z-10`}>
            <img
                src="/img-crypto/Logo_Dingo-transparent.png"
                alt="Logo da Cakto"
                className="-mt-7 -ml-4 md:-mt-0 w-28"
            />

            <div className="block md:hidden absolute top-5 right-4">
                {!showMenu ? (
                    <Menu className="text-white" size={32} onClick={handleShowMenu} />
                ) : <X className="text-white" onClick={handleShowMenu} />}
            </div>

            <nav
                className="flex flex-col justify-between mt-6"
                style={{ height: 'calc(100% - 100px)' }}
            >
                <div className="flex flex-col gap-6">
                    <ButtonNav
                        text="Inicio"
                        to="/affiliate/dashboard/inicio"
                        image={`${inicio ? '/Group 52.png' : '/Group 52-white.png'}`}
                        param={inicio}
                    />
                    <ButtonNav
                        text="Pesquisa de usuário"
                        to="/affiliate/dashboard/search-user"
                        image={`${searUser ? '/icon-user.png' : '/icon-user-white.png'}`}
                        param={searUser}
                    />
                    <ButtonNav
                        text="Configurações"
                        to="/affiliate/dashboard/account-settings"
                        image={`${settings ? '/icon-settings.png' : '/icon-settings-white.png'}`}
                        param={settings}
                    />
                    <ButtonNav
                        text="Documentos"
                        to="/affiliate/dashboard/document"
                        image={`${documents ? '/icon-document.png' : '/icon-document-white.png'}`}
                        param={documents}
                    />
                    <ButtonNav
                        text="Links"
                        to="/affiliate/dashboard/link"
                        image={`${links ? '/icon-links.png' : '/icon-links-white.png'}`}
                        param={links}
                    />
                    <ButtonNav
                        text="Saques"
                        to="/affiliate/dashboard/withdraw"
                        image={`${withdraw ? '/withdraw-gray.png' : '/withdraw-white.png'}`}
                        param={withdraw}
                    />
                </div>

                <Button
                    text="Sair"
                    isLoading={false}
                    onClick={logout}
                />
            </nav>
        </aside>
    )
}