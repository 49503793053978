import { ToastContainer } from "react-toastify";
import Router from "./routes/router";
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';
import ContextProvider from "./components/context/contextProvider";

function App() {
  return (
    <ContextProvider>
      <div className="relative w-full h-screen">
        <ToastContainer theme="dark" />
        <Router />
      </div>
    </ContextProvider>
  );
}

export default App;
