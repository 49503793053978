import { ReactNode } from "react"

interface SelectGroupProps {
    children: ReactNode;
    label?: string
    w?: string;
    bg?: string;
    value: string;
    onChange: (text: string) => void;
}

export function SelectGroup({
    children,
    label,
    w = 'w-[292px]',
    bg = 'bg-white',
    value,
    onChange
}: SelectGroupProps) {
    return (
        <div className="flex flex-col gap-1">
            {label && <label className="text-zinc-300">{label}</label>}
            <div className={`relative md:${w} h-[40px]`}>
                <select
                    className={`w-full h-full appearance-none outline-none ${bg} text-yellow-500 rounded-md px-2 md:px-6`}
                    value={value}
                    onChange={(text) => onChange(text.target.value)}
                >
                    {children}
                </select>
                <div className="absolute top-1/2 -translate-y-1/2 right-6">
                    <img
                        src="/Polygon 1.png"
                        alt="Icone chevron down"
                    />
                </div>
            </div>
        </div>
    )
}