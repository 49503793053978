import React from 'react';

export function EllipseBackground() {
    return (
        <div className="absolute w-[1156px] h-[1156px] top-[calc(50%-578px)] bg-gradient-to-r from-[#fcac06] to-[#f1bf73] blur-[450px] z-[1]"></div>
    );
}

export function BlackLine() {
    return (
        <div className="absolute w-[511px] h-[2px] bg-[#fcac06] bottom-0 left-0 rounded-b-[20px] z-[3]"></div>
    )
}