import { createContext, ReactNode, useState } from "react"
import { TransactionProps } from "../../types/transactionProps";

interface ContextProps {
    isLogged: boolean;
    handleIsLogged: (param: boolean) => void;
    transactionData: TransactionProps | null;
    handleTransaction: (data: TransactionProps) => void;
}

interface ContextProviderProps {
    children: ReactNode;
}

export const Context = createContext<ContextProps | undefined>(undefined);

export default function ContextProvider({ children }: ContextProviderProps) {
    const [isLogged, setIsLogged] = useState(false);
    const [transactionData, setTransactionData] = useState<TransactionProps | null>(null);

    const handleIsLogged = (param: boolean) => {
        setIsLogged(param);
    };

    const handleTransaction = (data: TransactionProps) => {
        setTransactionData(data);
    }

    return (
        <Context.Provider value={{ isLogged, handleIsLogged, transactionData, handleTransaction, }}>
            {children}
        </Context.Provider>
    )
}