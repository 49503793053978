import { Link } from "react-router-dom";

interface ButtonNavProps {
    text: string;
    to: string;
    image?: string;
    alt?: string;
    param: boolean;
}

export function ButtonNav({ text, to, image, alt, param }: ButtonNavProps) {
    return (
        <Link
            to={to}
            className={`flex items-center gap-4 ${param ? 'bg-white text-yellow-500' : 'text-white'} p-3 rounded-xl text-lg`}
        >
            {image && <img src={image} alt={alt} />}
            {text}
        </Link>
    )
}