interface ComponentMetodoProps {
    img: string;
    name: string;
    rede?: string;
    bgRede?: string;
    hover?: boolean;
    descRede: string;
    value: number | null;
    currency: string;
    onClick: () => void;
}

export function ComponentMetodo({
    img,
    name,
    rede,
    hover,
    bgRede,
    descRede,
    value,
    currency,
    onClick,
}: ComponentMetodoProps) {
    return (
        <div
            className={`relative w-full flex items-center p-3 rounded-lg ${hover ? 'bg-yellow-650' : 'bg-bgInput-100'}`}
            onClick={onClick}
        >
            <div className="flex items-center gap-2">
                <img
                    src={img}
                    alt={`Imagem ${name}`}
                />
                <div className="flex flex-col">
                    <div className="flex items-center gap-2">
                        <span className="text-textBlack-50">{name}</span>
                        {rede && <span className={`py-[2px] px-3 ${bgRede} rounded-2xl text-white text-xs`}>{rede}</span>}
                    </div>
                    <span className="text-textBlack-50">{descRede}</span>
                </div>
            </div>
            <div className="absolute top-2 right-2 flex items-center gap-1">
                <span className="text-textBlack-50">{value?.toLocaleString('pt-BR', {maximumFractionDigits: 2})}</span>
                <span className="py-[2px] px-3 bg-yellow-600 rounded-2xl text-white text-xs">{currency}</span>
            </div>
        </div>
    )
}