import { ChevronDown } from "lucide-react";
import { Button } from "../components/button";
import { InputSingle } from "../components/input";
import { Navbar } from "./components/navbar";
import { useEffect, useState } from "react";
import { ModalInfoUser } from "./components/modalInfoUser";
import { UserProps } from "../types/userProps";
import { api } from "../services/axios";
import { UsersDataProps } from "../types/usersData";
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default function SearchUser() {
    const [userStorage, setUserStorage] = useState<UserProps | null>(null);
    const [usersData, setUsersData] = useState<UsersDataProps[]>([]);
    const [detailsUser, setDetailsUser] = useState<UsersDataProps | null>(null);
    const [showInfoUser, setShowInfoUser] = useState(false);
    const [search, setSearch] = useState('');
    const [showMenu, setShowMenu] = useState(true);

    const handleShowMenu = () => {
        setShowMenu(!showMenu);
    }

    useEffect(() => {
        const getUserStorage = JSON.parse(localStorage.getItem('@dataAffiliateCrypto') ?? '{}');
        if (getUserStorage) {
            setUserStorage(getUserStorage);
            getUserWithAffiliate(getUserStorage.affiliate_code);
        }
    }, []);

    const getUserWithAffiliate = async (referredBy: string | undefined, filter?: string) => {
        try {
            const response = await api.post(`/v1/getUsersWithAffiliate?referred_by_code=${referredBy}`);
            if (response.data) {
                if (filter) {
                    const usersDataFilter = response.data.data.filter((user: any) => user === filter);
                    setUsersData(usersDataFilter);
                } else {
                    setUsersData(response.data.data);
                }
            }
        } catch (error: any) {
            console.log(error);
        }
    };

    const handleChangeInfoUser = (users?: UsersDataProps | null) => {
        if (users) {
            setDetailsUser(users);
        }
        setShowInfoUser(!showInfoUser);
    }

    return (
        <div className="relative w-full h-screen flex overflow-hidden bg-zinc-700">
            <Navbar
                inicio={false}
                searUser
                settings={false}
                documents={false}
                links={false}
                withdraw={false}
                handleShowMenu={handleShowMenu}
                showMenu={showMenu}
            />

            {showInfoUser &&
                <ModalInfoUser
                    handleChangeInfoUser={handleChangeInfoUser}
                    detailsUser={detailsUser}
                />}

            <div className="w-full h-full p-6">
                <h2 className="text-slate-200 text-2xl mt-14 md:mt-0">Pesquisa por usuário</h2>

                <div className="w-full flex items-center gap-6 mt-8">
                    <InputSingle
                        type="text"
                        label="Pesquisar"
                        placeholder="Pesquise por Nome, ou CPF, CNPJ,"
                        styleComp="bg-white"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="w-36 mt-3">
                        <Button
                            isLoading={false}
                            text="Pesquisar"
                            rounded="rounded-3xl"
                            onClick={() => getUserWithAffiliate(userStorage?.affiliate_code, search)}
                        />
                    </div>
                </div>

                <div className="w-full h-[80%] mt-6 overflow-x-auto overflow-y-auto no-scrollbar">
                    <table className="w-full text-center">
                        <thead>
                            <tr className="text-zinc-300">
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        ID <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        Nome <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        CPF <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        Taxa <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6 md:w-[60%] md:pr-20">
                                    <div className="flex justify-center items-center md:justify-end md:items-end gap-2">
                                        Data de registro <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        Referência Afiliado <ChevronDown />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {usersData?.length > 0 && (
                                usersData.map((users, index) => (
                                    <>
                                        <tr
                                            className="bg-gray-500 text-zinc-300 h-16 cursor-pointer"
                                            key={index}
                                            onClick={() => handleChangeInfoUser(users)}
                                        >
                                            <td className="rounded-l-xl pl-4">#{users?.id}</td>
                                            <td>{users?.name}</td>
                                            <td>{users?.document_number}</td>
                                            <td></td>
                                            <td className="md:text-end md:pr-32">
                                                {format(new Date(users?.created_at), 'dd/MM/yyyy', { locale: ptBR })}
                                            </td>
                                            <td>{users?.referred_by__code}</td>
                                        </tr>
                                        <br />
                                    </>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}