import { ChevronDown } from "lucide-react";
import { InputSingle } from "../components/input";
import { CardValuesDash } from "./components/cardValuesDash";
import { Navbar } from "./components/navbar";
import { SelectGroup } from "./components/selectGroup";
import { useEffect, useState } from "react";
import { UserProps, UsersTransactionsProps } from "../types/userProps";
import { api } from "../services/axios";
import { format } from "date-fns";
import { isAfter, isBefore, parseISO } from 'date-fns';

export default function Incio() {
    const [usersData, setUsersData] = useState<UsersTransactionsProps | null>(null);
    const [filteredTransactions, setFilteredTransactions] = useState<any[]>([]);
    const [status, setStatus] = useState('');
    const [moedasArr, setMoedasArr] = useState<string[]>([]);
    const [moedasSelected, setMoedasSelected] = useState('');
    const [cpfSelected, setCpfSelected] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [countAffiliate, setCountAffiliate] = useState(0);
    const [balance, setBalance] = useState(0);
    const [transactionCount, setTransactionCout] = useState(0);
    const [volume, setVolume] = useState(0);
    const getUserStorage: UserProps = JSON.parse(localStorage.getItem('@dataAffiliateCrypto') ?? '{}');
    const [showMenu, setShowMenu] = useState(true);

    useEffect(() => {
        const balancoAffiliate = async () => {
            try {
                const response = await api.post(`/v1/getAffiliateBalance?affiliate_code=${getUserStorage?.affiliate_code}`);
                if (response.data) {
                    setBalance(response.data.balance);
                }
            } catch (error: any) {
                console.log(error);
            }
        }
        const balancoLastHourAffiliate = async () => {
            try {
                const response = await api.post(`/v1/AffiliateTransactionCount24?affiliate_code=${getUserStorage?.affiliate_code}`);
                if (response.data) {
                    setTransactionCout(response.data.transaction_count_last_24h);
                }
            } catch (error: any) {
                console.log(error);
            }
        }
        const volumeLastHourAffiliate = async () => {
            try {
                const response = await api.post(`/v1/AffiliateVolume24?affiliate_code=${getUserStorage?.affiliate_code}`);
                if (response.data) {
                    setVolume(response.data.volume_last_24h_brl);
                }
            } catch (error: any) {
                console.log(error);
            }
        }
        balancoAffiliate();
        balancoLastHourAffiliate();
        volumeLastHourAffiliate();
    }, [getUserStorage?.affiliate_code]);

    useEffect(() => {
        const getTransactionUser = async () => {
            try {
                let statusFormatted;
                switch (status) {
                    case 'Confirmado':
                        statusFormatted = 'completed';
                        break;
                    case 'Cancelado':
                        statusFormatted = 'canceled';
                        break
                    default:
                        statusFormatted = 'completed';
                }
                const response = await api.post(`/v1/getAffiliateUsersTransactions?affiliate_code=${getUserStorage?.affiliate_code}&status=${statusFormatted}`);
                if (response.data) {
                    setUsersData(response.data);
                    setCountAffiliate(response.data.transactions.length);

                    const transactions = response.data.transactions;
                    let arrTransactions = new Set<string>(); // Utilizando Set para evitar duplicatas

                    for (let i = 0; i < transactions.length; i++) {
                        arrTransactions.add(transactions[i].coin); // Adiciona a moeda ao Set, ignorando duplicatas
                    }

                    setMoedasArr(Array.from(arrTransactions)); // Converte o Set de volta para um array
                }
            } catch (error) {
                console.log(error);
            }
        }
        getTransactionUser();
    }, [getUserStorage?.affiliate_code, status]);

    useEffect(() => {
        // Filtra as transações apenas se há datas ou um CPF selecionado
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;

        if (usersData) {
            const filtered = usersData.transactions
                .filter(transaction => {
                    const transactionDate = parseISO(transaction.created_at);

                    // Verifica as condições de data e CPF
                    const isWithinDateRange =
                        (!start || isAfter(transactionDate, start)) &&
                        (!end || isBefore(transactionDate, end));
                    const matchesCpf = cpfSelected ? transaction.document_number.includes(cpfSelected) : true;

                    return isWithinDateRange && matchesCpf;
                })
                .map(transaction => ({
                    operation_id: transaction.operation_id,
                    status: transaction.status,
                    document_number: transaction.document_number,
                    total_brl_amount: transaction.total_brl_amount,
                    crypto_amount: transaction.crypto_amount,
                    coin: transaction.coin,
                    market: transaction.market,
                    address: transaction.address,
                    network: transaction.network,
                    network_name: transaction.network_name,
                    affiliate_fee: transaction.affiliate_fee,
                    platform_fee: transaction.platform_fee,
                    created_at: transaction.created_at,
                    expires_at: transaction.expires_at,
                }));

            setFilteredTransactions(filtered);
        }
    }, [startDate, endDate, cpfSelected, usersData]);

    const handleShowMenu = () => {
        setShowMenu(!showMenu);
    }

    return (
        <div className="relative w-full h-screen flex overflow-hidden overflow-y-auto no-scrollbar bg-zinc-700">
            <Navbar
                inicio
                searUser={false}
                settings={false}
                documents={false}
                links={false}
                withdraw={false}
                handleShowMenu={handleShowMenu}
                showMenu={showMenu}
            />

            <div className="w-full h-full p-6">
                <h2 className="text-slate-200 text-2xl md:mt-0 mt-16">Dashboard Administrativo</h2>

                <div className="flex flex-wrap items-center justify-center md:justify-start gap-6 mt-8">
                    <CardValuesDash
                        text="Saldo disponível"
                        value={balance.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}
                        percentage="0,00"
                    />
                    <CardValuesDash
                        text="Volume 24h"
                        value={volume}
                        percentage="0,00"
                    />
                    <CardValuesDash
                        text="Registro 24h"
                        value={transactionCount}
                        percentage="0,00"
                    />
                    <CardValuesDash
                        text="Número de afiliados"
                        value={countAffiliate}
                        percentage="0,00"
                    />
                </div>

                <div className="flex items-center justify-between mt-10">
                    <h2 className="text-slate-200 text-2xl">Atividade</h2>
                    {/* <div className="w-40">
                        <Button
                            text="Pesquisar"
                            isLoading={false}
                            rounded="rounded-3xl"
                        />
                    </div> */}
                </div>

                <div className="flex flex-col md:flex-row gap-6 mt-4">
                    <div className="flex gap-3">
                        <div className="w-[50%]">
                            <SelectGroup
                                label="Status de transação"
                                value={status}
                                onChange={setStatus}
                                children={
                                    <>
                                        <option>Confirmado</option>
                                        <option>Cancelado</option>
                                    </>
                                }
                            />
                        </div>
                        <div className="w-[50%]">
                            <SelectGroup
                                label="Moeda selecionada"
                                value={moedasSelected}
                                onChange={setMoedasSelected}
                                children={
                                    <>
                                        {moedasArr.map((moeda, index) => (
                                            <option key={index}>{moeda}</option>
                                        ))}
                                    </>
                                }
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 w-full md:w-[292px]">
                        <label className="text-zinc-300">Período</label>
                        <div className="flex">
                            <div className="md:w-[146px] w-full">
                                <input
                                    type="date"
                                    placeholder="00/00/0000"
                                    className="w-full h-[40px] outline-none bg-white text-yellow-500 appearance-none rounded-l-md px-2"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            <div className="md:w-[146px] w-full">
                                <input
                                    type="date"
                                    placeholder="00/00/0000"
                                    className="w-full h-[40px] outline-none bg-white text-yellow-500 appearance-none rounded-r-md px-2"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-[292px]">
                        <InputSingle
                            label="CPF/CNPJ do usuário"
                            placeholder="000.000.000-00"
                            type="text"
                            h="h-[40px]"
                            styleComp="outline-none bg-white text-yellow-500 rounded-md"
                            value={cpfSelected}
                            onChange={(e) => setCpfSelected(e.target.value)}
                        />
                    </div>
                </div>
                <div className="w-full h-[55%] mt-6 overflow-x-auto overflow-y-auto no-scrollbar">
                    <table className="w-full text-center">
                        <thead>
                            <tr className="text-zinc-300">
                                <th className="pb-3 text-center px-10">
                                    <div className="flex justify-center items-center gap-2">
                                        ID
                                        <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        Moeda <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        Valor <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        Taxa <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        Status <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        Data <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        Usuário <ChevronDown />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTransactions.length > 0 ?
                                filteredTransactions.map((transaction, index) => (
                                    <>
                                        <tr className="bg-gray-500 text-zinc-300 h-16" key={index}>
                                            <td className="py-4 px-2 w-60">{transaction.operation_id}</td>
                                            <td className="py-4">{transaction.coin}</td>
                                            <td className="py-4">{transaction.total_brl_amount.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
                                            <td className="py-4">{transaction.affiliate_fee}</td>
                                            <td className="py-4">{transaction.status}</td>
                                            <td className="py-4">{format(new Date(transaction.created_at), "dd/MM/yyyy")}</td>
                                            <td className="py-4">{transaction.document_number}</td>
                                        </tr>
                                        <br />
                                    </>
                                )) : (
                                    <tr>
                                        <td colSpan={7} className="py-4 text-white">Nenhuma transação encontrada</td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}