import { useEffect, useState } from "react";
import { Button } from "../../components/button";
import { UsersDataProps } from "../../types/usersData";
import { api } from "../../services/axios";
import { X } from "lucide-react";
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Input } from "../../components/input";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { transactionsProps } from "../../types/userProps";
import { toast, ToastContainer } from "react-toastify";
interface ModalInfoUserProps {
    handleChangeInfoUser: (users?: UsersDataProps | null) => void;
    detailsUser: UsersDataProps | null
}

interface DivInfoProps {
    title: string;
    text: string;
}

const formSchema = z.object({
    percentage_user: z.string(),
})

function DivInfo({ title, text }: DivInfoProps) {
    return (
        <div className="flex flex-col">
            <span className="text-zinc-400/30 text-sm">{title}</span>
            <span className="text-sm">{text}</span>
        </div>
    )
}

export function ModalInfoUser({
    handleChangeInfoUser,
    detailsUser,
}: ModalInfoUserProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [editPercentage, setEditPercentage] = useState(false);
    const [dataWithdraw, setDataWithdraw] = useState<transactionsProps[]>([]);
    const [dataWithdrawFilttered, setDataWithdrawFilttered] = useState<transactionsProps[]>([]);
    const [filtteredMode, setFiltteredMode] = useState('all');
    const getDataUser = JSON.parse(localStorage.getItem('@dataAffiliateCrypto') ?? '{}');

    const handlePercentage = () => {
        setEditPercentage(!editPercentage);
    }

    const {
        register,
        handleSubmit,
        setValue,
        watch,
    } = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: { percentage_user: '' }
    })

    const percentageUser = watch('percentage_user');

    useEffect(() => {
        let newPercentage = percentageUser.replace('%', '').replace(',', '.').trim();
    
        // Permite que o usuário edite livremente sem adicionar "%" durante a digitação
        setValue('percentage_user', newPercentage, { shouldValidate: true, shouldDirty: true });
    
        // Se o campo estiver vazio, termina aqui para permitir que o usuário apague o conteúdo
        if (newPercentage === '') {
            return;
        }
    
        // Adiciona "0." se o usuário digitar "0" sem um ponto decimal ainda
        if (newPercentage === '0') {
            setValue('percentage_user', '0.', { shouldValidate: true, shouldDirty: true });
            return;
        }
    
        // Insere o ponto decimal automaticamente se o valor estiver entre 1 e 4
        if (/^[1-4]$/.test(newPercentage)) {
            setValue('percentage_user', `${newPercentage}.`, { shouldValidate: true, shouldDirty: true });
            return;
        }
    
        // Limita a parte decimal a até 3 dígitos
        if (newPercentage.includes('.')) {
            const [integerPart, decimalPart] = newPercentage.split('.');
            if (decimalPart && decimalPart.length > 3) {
                newPercentage = `${integerPart}.${decimalPart.slice(0, 3)}`;
            }
        }
    
        // Limita o valor até 5 e adiciona o símbolo de porcentagem quando necessário
        const numericValue = parseFloat(newPercentage);
        if (!isNaN(numericValue) && numericValue <= 5) {
            setValue('percentage_user', `${newPercentage}%`, { shouldValidate: true, shouldDirty: true });
        } else if (newPercentage === '0.') {
            setValue('percentage_user', '0.', { shouldValidate: true, shouldDirty: true });
        } else {
            // Limpa o campo se o valor for inválido
            setValue('percentage_user', '', { shouldValidate: true, shouldDirty: true });
        }
    }, [percentageUser, setValue]);    

    useEffect(() => {
        getDetailsUser();
    }, [detailsUser?.document_number, getDataUser?.affiliate_code]);

    const getDetailsUser = async () => {
        const response = await api.post(`/v1/getAffiliateUsersTransactions?affiliate_code=${getDataUser?.affiliate_code}&status=`);
        if (response.data) {
            const filtteredUser = response.data.transactions.filter((user: any) => user.document_number === detailsUser?.document_number)
            setDataWithdraw(filtteredUser);
        }
    }

    useEffect(() => {
        switch (filtteredMode) {
            case 'all':
                setDataWithdrawFilttered([]);
                break;
            case 'pending':
                const newDataWithdrawPending = dataWithdraw.filter((withdraw) => withdraw.status === 'pending');
                setDataWithdrawFilttered(newDataWithdrawPending);
                break;
            case 'completed':
                const newDataWithdrawCompleted = dataWithdraw.filter((withdraw) => withdraw.status === 'completed');
                setDataWithdrawFilttered(newDataWithdrawCompleted);
                break;
            case 'canceled':
                const newDataWithdrawCanceled = dataWithdraw.filter((withdraw) => withdraw.status === 'canceled');
                setDataWithdrawFilttered(newDataWithdrawCanceled);
                break;
        }
    }, [dataWithdraw, filtteredMode]);

    const onSubmitPeer = async (value: z.infer<typeof formSchema>) => {
        setIsLoading(true);
        let newPercentage = parseFloat(value.percentage_user.replace('%', '')) / 100;
        console.log(newPercentage)
        try {
            const response = await api.post(`/v1/UpdateUserAffiliateFee`, {
                affiliate_code: getDataUser?.affiliate_code,
                user_id: detailsUser?.id,
                new_user_fee: newPercentage,
            });
            if (response.data) {
                toast.success('Taxa atualizada com sucesso.');
            }
        } catch (error: any) {
            toast.error(error.response.data.detail);
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const formattedDate = detailsUser?.birthday
        ? format(new Date(detailsUser.birthday), 'dd/MM/yyyy')
        : '';
    const formattedCreatedAt = detailsUser?.created_at ?
        format(new Date(detailsUser?.created_at), 'dd/MM/yyyy HH:mm', { locale: ptBR }) : ''

    return (
        <div className="absolute top-0 left-0 w-full h-screen bg-zinc-850 flex flex-col gap-10 justify-center items-center overflow-hidden z-10">
            <ToastContainer theme="dark" />
            <div className="relative w-full max-w-3xl flex flex-col bg-white rounded-xl p-6">
                <button className="absolute top-5 right-4" onClick={() => editPercentage ? handlePercentage() : handleChangeInfoUser(null)}>
                    <X className="text-yellow-500" size={26} />
                </button>
                <h2 className="text-lg font-semibold">{editPercentage ? 'Editar taxa do usuário' : 'Informações do usuário'}</h2>

                {editPercentage ? (
                    <div>
                        <form onSubmit={handleSubmit(onSubmitPeer)} className="mt-2">
                            <Input
                                type="text"
                                name="percentage_user"
                                placeholder="Mark up Fee"
                                label="Editar taxa"
                                register={register}
                            />
                            <Button
                                text="Editar taxa"
                                isLoading={isLoading}
                                type="submit"
                            />
                        </form>
                    </div>
                ) : (
                    <>
                        <div className="flex items-center gap-8 mt-3 pb-2 border-b border-zinc-400/40">
                            <DivInfo
                                title="Nome"
                                text={detailsUser?.name || ''}
                            />
                            <DivInfo
                                title="CPF"
                                text={detailsUser?.document_number || ''}
                            />
                            <DivInfo
                                title="Data de nascimento"
                                text={formattedDate}
                            />
                            <DivInfo
                                title="Data de registro"
                                text={formattedCreatedAt}
                            />
                            <DivInfo
                                title="Limite"
                                text={Number(detailsUser?.limit).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) || 'Não informado'}
                            />
                        </div>

                        <div className="w-full mt-4 border-b border-zinc-400/40">
                            <div className="flex items-center gap-4">
                                <div className="w-32">
                                    <Button
                                        size="text-sm"
                                        isLoading={false}
                                        h="h-6"
                                        text="Todos"
                                        rounded="rounded-3xl"
                                        styleComp={filtteredMode === 'all' ? '' : 'bg-transparent text-yellow-500'}
                                        onClick={() => setFiltteredMode('all')}
                                    />
                                </div>
                                <div className="w-32 mx-4">
                                    <Button
                                        size="text-sm"
                                        isLoading={false}
                                        text="Aguardando"
                                        h="h-6"
                                        rounded="rounded-3xl"
                                        styleComp={filtteredMode === 'pending' ? '' : 'bg-transparent text-yellow-500'}
                                        onClick={() => setFiltteredMode('pending')}
                                    />
                                </div>
                                <div className="w-32">
                                    <Button
                                        size="text-sm"
                                        isLoading={false}
                                        text="Completado"
                                        h="h-6"
                                        rounded="rounded-3xl"
                                        styleComp={filtteredMode === 'completed' ? '' : 'bg-transparent text-yellow-500'}
                                        onClick={() => setFiltteredMode('completed')}
                                    />
                                </div>
                                <div className="w-32">
                                    <Button
                                        size="text-sm"
                                        isLoading={false}
                                        text="Cancelado"
                                        h="h-6"
                                        rounded="rounded-3xl"
                                        styleComp={filtteredMode === 'canceled' ? '' : 'bg-transparent text-yellow-500'}
                                        onClick={() => setFiltteredMode('canceled')}
                                    />
                                </div>
                            </div>

                            <div className="w-full h-full max-h-[140px] mt-4 mb-2 overflow-x-auto overflow-y-auto no-scrollbar">
                                <table className="w-full text-left">
                                    <thead>
                                        <tr className="text-zinc-300/60">
                                            <th>ID</th>
                                            <th>Método</th>
                                            <th>Valor</th>
                                            <th>Status</th>
                                            <th>Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataWithdrawFilttered.length > 0 ?
                                            dataWithdrawFilttered?.map((withdraw, index) => (
                                                <tr
                                                    key={index}
                                                    className="text-sm"
                                                >
                                                    <td>{withdraw?.operation_id}</td>
                                                    <td>{withdraw?.coin}</td>
                                                    <td>{withdraw?.crypto_amount}</td>
                                                    <td>
                                                        {
                                                            withdraw?.status === 'pending' ? 'Aguardando' :
                                                                withdraw?.status === 'completed' ? 'Completado' :
                                                                    withdraw?.status === 'canceled' ? 'Cancelado' : ''
                                                        }
                                                    </td>
                                                    <td>{format(new Date(withdraw?.created_at), 'dd/mm/yyyy HH:mm')}</td>
                                                </tr>
                                            ))
                                            : (
                                                dataWithdraw?.map((withdraw, index) => (
                                                    <tr
                                                        key={index}
                                                        className="text-sm"
                                                    >
                                                        <td>{withdraw?.operation_id}</td>
                                                        <td>{withdraw?.coin}</td>
                                                        <td>{withdraw?.crypto_amount}</td>
                                                        <td>
                                                            {
                                                                withdraw?.status === 'pending' ? 'Aguardando' :
                                                                    withdraw?.status === 'completed' ? 'Completado' :
                                                                        withdraw?.status === 'canceled' ? 'Cancelado' : ''
                                                            }
                                                        </td>
                                                        <td>{format(new Date(withdraw?.created_at), 'dd/mm/yyyy HH:mm')}</td>
                                                    </tr>
                                                ))
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="flex flex-col mt-4">
                            <div className="flex items-center justify-end">
                                {/* <h2 className="text-lg font-semibold">Informações do usuário</h2> */}
                                <div className="w-32">
                                    <Button
                                        size="text-sm"
                                        isLoading={false}
                                        text="Editar taxa"
                                        rounded="rounded-3xl"
                                        h="h-6"
                                        onClick={handlePercentage}
                                    />
                                </div>
                            </div>
                            {/* <div className="flex items-center gap-14 mt-3">
                        <DivInfo
                            title="Banco"
                            text="Banco Inter"
                        />
                        <DivInfo
                            title="Tipo"
                            text="PIX"
                        />
                        <DivInfo
                            title="Chave Pix"
                            text="CPF"
                        />
                        <DivInfo
                            title="Taxa"
                            text="10%"
                        />
                    </div> */}

                            {/* <div className="w-full">
                        <SelectGroup
                            value={status}
                            onChange={setStatus}
                            label="Tipo de transação"
                            w="w-full"
                            bg="bg-gray-100"
                            children={
                                <>
                                    <option>Real (BRL)  <div className="pl-32 text-black">0,00</div></option>
                                    <option></option>
                                </>
                            }
                        />
                    </div> */}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}