interface ToastProps {
    img: string;
    alt: string;
    text: string;
    desc?: string;
}

export function Toast({ img, alt, text, desc, }: ToastProps) {
    return (
        <div className={`toast-anim absolute max-sm:top-0 md:bottom-6 flex items-center gap-2 max-w-96 w-full h-16 px-3 py-4 bg-white shadow-lg rounded-2xl z-50`}>
            <img
                src={img}
                alt={alt}
            />
            <div className="flex flex-col">
                <h3 className="text-yellow-500 font-bold">{text}</h3>
                {desc && <p className="text-textBlack-100 text-sm">{desc}</p>}
            </div>
        </div>
    )
}