import { Loader } from 'lucide-react';
import React from 'react';

interface ButtonProps {
    type?: "button" | "submit"
    text: string
    h?: string;
    size?: string;
    styleComp?: string;
    rounded?: string;
    disabled?: boolean;
    isLoading: boolean
    onClick?: () => void;
}

export function Button({
    type = 'button',
    text,
    h = 'h-12',
    size = 'text-[16px]',
    styleComp,
    isLoading,
    onClick,
    rounded,
    disabled,
}: ButtonProps) {
    return (
        <button
            type={type}
            className={`w-full ${h} flex justify-center items-center font-bold ${size}  ${styleComp || 'bg-yellow-600 hover:bg-yellow-600 text-white'} ${rounded || 'rounded-xl'}`}
            onClick={onClick}
            disabled={disabled}
        >
            {isLoading ? <Loader className='animate-spin' /> : text}
        </button>
    )
}