import { BrowserRouter, Route, Routes } from 'react-router-dom';
import WhatsAppPage from "../WhatsAppPage";
import Register from "../register";
import { TransactionType } from "../TransactionType";
import ConfirmedTransaction from "../components/confirmedTransaction";
import Incio from "../afiliados/inicio";
import SearchUser from "../afiliados/searchUser";
import { AccountSettings } from "../afiliados/accountSettings";
import { Link } from "../afiliados/link";
import { Document } from "../afiliados/document";
import { ComponentType } from 'react';
import Withdraw from '../afiliados/withdraw';
import VerifyUser from '../verify-user';
interface PrivateProps {
    Item: ComponentType
}

const PrivateUser = ({ Item }: PrivateProps): any => {
    let Authorized = false;
    const dataUser: any = JSON.parse(localStorage.getItem('@dataUserCrypto') ?? '{}');
    if (dataUser) {
        if (dataUser) {
            Authorized = true;
        } else {
            Authorized = false;
        }
    }
    return Authorized ? <Item /> : window.location.href = '/';
}

const PrivateAffiliate = ({ Item }: PrivateProps): any => {
    let AffiliateAuthorized = false;
    const codeAffiliate: any = JSON.parse(sessionStorage.getItem('#affiliate-verified') ?? '{}');
    if (codeAffiliate) {
        if (codeAffiliate?.verified) {
            AffiliateAuthorized = true;
        }
    } else {
        AffiliateAuthorized = false;
    }
    return AffiliateAuthorized ? <Item /> : window.location.href = '/affiliate';
}

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:param?" element={<WhatsAppPage />} />
                <Route path="/register/:codeAffiliate?" element={<Register />} />
                <Route path="/verify-user/:type?" element={<VerifyUser />} />
                <Route path="/transaction/:type?" element={<PrivateUser Item={TransactionType} />} />
                <Route path="/confirmed-check" element={<PrivateUser Item={ConfirmedTransaction} />} />

                {/** Routes to affiliates */}
                <Route path="/affiliate/dashboard/inicio" element={<PrivateAffiliate Item={Incio} />} />
                <Route path="/affiliate/dashboard/search-user" element={<PrivateAffiliate Item={SearchUser} />} />
                <Route path="/affiliate/dashboard/account-settings" element={<PrivateAffiliate Item={AccountSettings} />} />
                <Route path="/affiliate/dashboard/document" element={<PrivateAffiliate Item={Document} />} />
                <Route path="/affiliate/dashboard/link" element={<PrivateAffiliate Item={Link} />} />
                <Route path="/affiliate/dashboard/withdraw" element={<PrivateAffiliate Item={Withdraw} />} />
            </Routes>
        </BrowserRouter>
    )
}