import { useState } from "react";
import { InputSingle } from "../components/input";
import { Navbar } from "./components/navbar";
import { ChevronRight } from "lucide-react";

export function Document() {
    const [document, setDocument] = useState('');
    const [showMenu, setShowMenu] = useState(true);

    const handleShowMenu = () => {
        setShowMenu(!showMenu);
    }


    return (
        <div className="relative w-full h-screen flex overflow-hidden bg-zinc-700">
            <Navbar
                inicio={false}
                searUser={false}
                settings={false}
                documents
                links={false}
                withdraw={false}
                handleShowMenu={handleShowMenu}
                showMenu={showMenu}
            />

            <div className="w-full h-full p-6">
                <h2 className="text-slate-200 text-xl md:text-2xl mt-14 md:mt-0">Documentação da API</h2>

                <div className="flex flex-col w-full max-w-xl gap-3 mt-4 md:mt-8">
                    <div className="relative">
                        <InputSingle
                            type="text"
                            label="Documentação de Integração do Crypto as a Service"
                            placeholder="Acessar"
                            styleComp="bg-white pr-10"
                            value={document}
                            onChange={(e) => setDocument(e.target.value)}
                        />
                        <button className="absolute top-1/2 -translate-y-1/2 right-4 mt-[17px] md:mt-[6px] p-1 bg-yellow-500 rounded-full">
                            <ChevronRight color="white" size={18} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}