import { useState } from "react";
import { Button } from "./components/button";
import { EllipseBackground } from "./components/ellipse_background";
import { Input, } from "./components/input";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Toast } from "./components/toast";
import { api } from "./services/axios";
import { useNavigate, useParams } from "react-router-dom";

const formSchema = z.object({
    code: z.string(),
});

export default function VerifyUser() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [success, setSuccess] = useState(false);
    const { type } = useParams();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
    });

    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        setIsLoading(true);
        let dataVerify: any = ''
        switch (type) {
            case 'transactions':
                dataVerify = JSON.parse(localStorage.getItem('#dataVerify') ?? '{}');
                break;
            case 'affiliate':
                dataVerify = JSON.parse(localStorage.getItem('#affiliate-verified') ?? '{}');
                break;
        }
        try {
            const response = await api.post(`/v1/verifyCode?phone=${dataVerify?.phone}&code=${values.code}`);
            if (response.data.success) {
                setSuccess(true);
                const formattedUser = { verified: true };
                switch (type) {
                    case 'transactions':
                        localStorage.removeItem('#dataVerify');
                        sessionStorage.setItem('#user-verified', JSON.stringify(formattedUser));
                        setTimeout(() => {
                            navigate('/transaction/comprar');
                        }, 2000);
                        break;
                    case 'affiliate':
                        localStorage.removeItem('#dataVerify');
                        sessionStorage.setItem('#affiliate-verified', JSON.stringify(formattedUser));
                        setTimeout(() => {
                            navigate('/affiliate/dashboard/inicio');
                        }, 2000);
                        break;
                }
            } else {
                setErrorMessage(true);
                reset();

            }
        } catch (error: any) {
            setErrorMessage(true);
            console.log(error);
        } finally {
            setSuccess(false);
            setIsLoading(false);
        }
    }

    return (
        <div className="relative w-full h-screen bg-zinc-800 flex flex-col gap-10 justify-center items-center overflow-hidden px-2 md:px-0">
            {/* Ellipse Background */}
            <EllipseBackground />
            <ToastContainer theme="dark" />

            {success &&
                <Toast
                    img="/check.png"
                    alt="Imagem de check"
                    text="Login feito com sucesso!"
                    desc="Redirecionando para tela de operaçõoes..."
                />}
            <div className="relative flex flex-col items-center w-full p-5 z-[2] gap-3 mx-2 py-10 md:max-w-[511px] bg-white rounded-xl">
                <img
                    src="/img-crypto/Logo Dingo-white.png"
                    alt="Logo Dingo"
                    className="absolute top-2 left-4 w-20"
                />
                <div className="w-full max-w-[400px] flex flex-col">
                    <h2 className="text-xl font-bold text-center mt-4 text-textBlack-100">
                        Verificação 2FA
                    </h2>

                    <form className="flex flex-col w-full mt-6" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <div onClick={() => setErrorMessage(false)}>
                                <Input
                                    type="number"
                                    name="code"
                                    register={register}
                                    placeholder='Digite o código enviado.'
                                    error={errors.code?.message}
                                    borderColor={errorMessage ? 'border-red-500' : ''}
                                    gray
                                />
                            </div>
                            {errorMessage && <p className="text-red-500 text-sm">Digite o código correto!</p>}
                            <div className='flex flex-col gap-6 w-full mt-4'>
                                <Button
                                    type="submit"
                                    text='Enviar'
                                    isLoading={isLoading}
                                />
                                <Button
                                    type="button"
                                    text='Voltar'
                                    isLoading={false}
                                    styleComp="bg-transparent text-zinc-600"
                                    onClick={() => {
                                        switch (type) {
                                            case 'transactions':
                                                navigate('/');
                                                break;
                                            case 'affiliate':
                                                navigate('/affiliate');
                                                break;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {errorMessage && (
                <div className="bg-red-500 w-full max-w-[511px] rounded-lg p-7">
                    <h2 className="text-slate-50 text-xl mb-1">Ops, algo deu errado.</h2>
                    <p className="text-zinc-200">Verifique o código e tente novamente.</p>
                </div>
            )}
        </div>
    )
}