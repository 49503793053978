import { ChevronDown } from "lucide-react";
import { Navbar } from "./components/navbar";
// import { format } from "date-fns";
import { useEffect, useState } from "react";
import { api } from "../services/axios";
import { WithdrawalProps } from "../types/withdrawProps";

export default function Withdraw() {
    const [dataWithdraw, setDataWithdraw] = useState<WithdrawalProps[]>([]);
    const [showMenu, setShowMenu] = useState(true);
    const getDataUser = JSON.parse(localStorage.getItem('@dataAffiliateCrypto') ?? '{}');

    useEffect(() => {
        const getWithdraw = async () => {
            try {
                const response = await api.post(`/v1/AffiliateWithdrawals?affiliate_code=${getDataUser?.affiliate_code}`);
                if (response.data) {
                    setDataWithdraw(response.data.withdrawals);
                }
            } catch (error: any) {
                console.log(error);
            }
        }
        getWithdraw();
    }, [getDataUser?.affiliate_code]);

    const handleShowMenu = () => {
        setShowMenu(!showMenu);
    }

    return (
        <div className="relative w-full h-screen flex overflow-hidden bg-zinc-700">
            <Navbar
                inicio={false}
                searUser={false}
                settings={false}
                documents={false}
                links={false}
                withdraw
                handleShowMenu={handleShowMenu}
                showMenu={showMenu}
            />

            <div className="w-full h-full p-6">
                <h2 className="text-slate-200 text-2xl mt-14 md:mt-0">Saques</h2>
                <p className="text-slate-200/60 mt-4 md:text-lg">Seu saque será processado automaticamente na próxima segunda-feira, considerando o total de taxas acumuladas até domingo, 23:59.</p>
                <div className="w-full h-[80%] mt-6 overflow-x-auto overflow-y-auto no-scrollbar">
                    <table className="w-full text-center">
                        <thead>
                            <tr className="text-zinc-300">
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        ID <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        Ação <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        CPF <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        Valor <ChevronDown />
                                    </div>
                                </th>
                                <th className="pb-3 text-center px-6">
                                    <div className="flex justify-center items-center gap-2">
                                        Retirada <ChevronDown />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataWithdraw?.map((withdraw, index) => (
                                <>
                                    <tr
                                        className="bg-gray-500 text-zinc-300 h-16 cursor-pointer"
                                        key={index}
                                    >
                                        <td className="rounded-l-xl pl-4">{withdraw?.id}</td>
                                        <td>{withdraw?.acao}</td>
                                        <td>{withdraw?.cpf_cnpj}</td>
                                        <td>{withdraw?.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        {/* <td className="text-end pr-32">
                                            {format(new Date(users?.created_at), 'dd/MM/yyyy', { locale: ptBR })}
                                        </td> */}
                                        <td>{withdraw?.data}</td>
                                    </tr>
                                    <br />
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}