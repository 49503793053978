import React, { useState } from 'react';
import { InputFormMask } from './components/input';
import { Button } from './components/button';
import { EllipseBackground } from './components/ellipse_background';
import { toast, ToastContainer } from 'react-toastify';
import { api } from './services/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const formSchema = z.object({
  whatsapp: z.string(),
})

const WhatsAppPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { param } = useParams();

  const {
    register,
    handleSubmit,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const handleSignin = async (values: z.infer<typeof formSchema>) => {
    setIsLoading(true);
    try {
      const phoneFormatted = values.whatsapp.replace(/\D/g, '');
      const response = await api.post('/v1/verifyUser', {
        phone: phoneFormatted,
      });
      if (response.data) {
        if (response.data.success === false) {
          if (response.data.message === 'Erro interno no servidor.') {
            toast.error(response.data.message);
          } else {
            toast.warn('Usuário não encontrado! Você será redirecionado(a) para o cadastro.');
            setTimeout(() => {
              navigate('/register');
            }, 2000)
          }
        }
        if (response.data.data) {
          if (param === 'affiliate' && response.data.data.affiliate_code !== "None") {
            const formattData = {
              phone: response.data.data.user_phone,
            }
            await api.post('/v1/start2fa', {
              phone: response.data.data.user_phone,
              UserDocument: response.data.data.user_document,
            });
            localStorage.setItem('@dataAffiliateCrypto', JSON.stringify(response.data.data));
            localStorage.setItem('#affiliate-verified', JSON.stringify(formattData));
            toast.success('Código de verificação enviado.');
            setTimeout(() => {
              navigate('/verify-user/affiliate');
            }, 1500);
          } else {
            // const formattData = {
            //   phone: response.data.data.user_phone,
            // }
            // await api.post('/v1/start2fa', {
            //   phone: response.data.data.user_phone,
            //   UserDocument: response.data.data.user_document,
            // });
            if(param === 'affiliate') {
              toast.error('Usuário não cadastrado como afiliado.');
              return;
            }
            localStorage.setItem('@dataUserCrypto', JSON.stringify(response.data.data));
            navigate('/transaction/comprar');
            // localStorage.setItem('#dataVerify', JSON.stringify(formattData));
            // toast.success('Código de verificação enviado.');
            // setTimeout(() => {
            //   navigate('/verify-user/transactions');
            // }, 1500);
          }
        }
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="relative w-full h-screen bg-zinc-800 flex justify-center items-center overflow-hidden">
      {/* Ellipse Background */}
      <EllipseBackground />
      <ToastContainer theme="dark" />

      <div className="relative flex flex-col items-center w-full p-5 z-[2] gap-3 mx-2 py-10 md:max-w-[511px] bg-white rounded-xl">
      <img
        src="/img-crypto/Logo Dingo-white.png"
        alt="Logo Dingo"
        className="absolute top-2 left-4 w-20"
      />
        <div className="w-full max-w-[400px] flex flex-col">
          <h2 className="text-xl text-center font-bold text-zinc-800 mt-4">Coloque o seu número de whatsapp</h2>
          <p className='text-textBlack-100 text-center text-sm'>Preencha os campos com os seus dados</p>

          <form className="flex flex-col w-full mt-8" onSubmit={handleSubmit(handleSignin)}>
            <InputFormMask
              name='whatsapp'
              register={register}
              type='tel'
              placeholder='Número de whatsapp'
              gray
              mask="(99) 99999-9999"
            />
            <div className='w-full'>
              <Button
                type='submit'
                text='Enviar'
                isLoading={isLoading}
              />
            </div>

            {/* <div className='flex items-center justify-between mt-4'>
              <Link to="/register" className='text-sm'>Registrar</Link>
              {!param ? (
                <Link to="/affiliate" className='text-sm'>Entrar como afiliado?</Link>
              ) : <Link to="/">Entrar para transações de crypto?</Link>}
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppPage;
