import QRCode from 'react-qr-code';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Toast } from './toast';
import { api } from '../services/axios';
import { useParams } from 'react-router-dom';
import { Button } from './button';
import { TransactionProps } from '../types/transactionProps';
interface PaymentProps {
    handleShowPayment: () => void;
    descRede: string;
    network: string | null;
    networkName: string | null;
}

export function PaymentQrcode({
    network,
    networkName,
    handleShowPayment,
}: PaymentProps) {
    const initialTime = 60 * 6;
    const [time, setTime] = useState<number>(() => {
        // Recupera o tempo do sessionStorage, ou inicia com o tempo padrão
        const storedTime = sessionStorage.getItem('remainingTime');
        return storedTime ? parseInt(storedTime, 10) : initialTime;
    });
    const [copyStatus, setCopyStatus] = useState<string | null>(null);
    const [showBlur, setShowBlur] = useState(false);
    const transactionDataStorage: TransactionProps = JSON.parse(localStorage.getItem('@transactionData') ?? '{}');
    const { type } = useParams();

    useEffect(() => {
        const interval = setInterval(() => {
            setTime((prevTime) => {
                if (prevTime <= 60) {
                    setShowBlur(true);
                    if (prevTime === 1) {
                        clearInterval(interval);
                        sessionStorage.removeItem('remainingTime');
                        if (type === 'comprar') {
                            completePurchase().then((response) => {
                                if (response) {
                                    window.location.href = '/confirmed-check';
                                    localStorage.removeItem('@transactionData');
                                } else {
                                    window.location.href = '/transaction/comprar';
                                    localStorage.removeItem('@transactionData');
                                }
                            });
                        } else {
                            completeSale().then((response) => {
                                if (response) {
                                    window.location.href = '/confirmed-check';
                                    localStorage.removeItem('@transactionData');
                                } else {
                                    window.location.href = '/transaction/comprar';
                                    localStorage.removeItem('@transactionData');
                                }
                            })
                        }
                        return 0;
                    }
                }
                const newTime = prevTime - 1;
                sessionStorage.setItem('remainingTime', String(newTime)); // Armazena o tempo restante
                return newTime;
            });
        }, 1000);

        return () => clearInterval(interval); // Limpa o intervalo quando o componente desmonta
    }, []);

    // const context = useContext(Context);
    // if (!context) {
    //     return window.location.href = '/';
    // }

    // const { transactionData } = context;

    if (!transactionDataStorage) {
        return window.location.href = '/';
    }

    async function completePurchase() {
        try {
            const response = await api.post('/v1/completePurchase', {
                operation_id: transactionDataStorage?.operation_id,
                phone: transactionDataStorage?.phone,
            });
            return response;
        } catch (error) {
            console.error("Erro ao completar a compra:", error);
            return null;
        }
    }
    async function completeSale() {
        try {
            const response = await api.post('/v1/completeSale', {
                operation_id: transactionDataStorage?.operation_id,
                pixKey: transactionDataStorage?.pixKey,
                phone: transactionDataStorage?.phone,
            });
            return response;
        } catch (error) {
            console.error("Erro ao completar a venda:", error);
            return null;
        }
    }

    const handleCopy = (text: string) => {
        setCopyStatus(`Copiado. ${text}`);
        setTimeout(() => { setCopyStatus(null); }, 3000);
    };

    return (
        <>
            {/* <ToastContainer theme="dark" /> */}
            <div className="absolute top-0 left-0 w-full h-full bg-zinc-850 flex flex-col gap-10 md:items-center md:justify-center md:overflow-hidden z-20">

                <div className="w-full max-w-[760px] bg-white h-full md:max-h-[780px] p-6 md:rounded-xl overflow-y-auto z-50">
                    <div className="w-full p-3 flex items-center gap-3 bg-bgInput-100 rounded-lg">
                        <img src="/exclamacao-blue.png" alt="Logo pix" />
                        <span className="text-blue-200 text-sm">
                            E-mail: contato@dingousdt.com.br
                        </span>
                    </div>
                    {type === 'vender' ? (
                        <div className="w-full p-3 flex items-center gap-3 bg-bgInput-100 rounded-lg mt-2">
                            <img src="/exclamacao-blue.png" alt="Logo pix" />
                            <span className="text-blue-200 text-sm">
                                Envie {network?.toUpperCase()} na rede {networkName}
                            </span>
                        </div>
                    ) : (
                        <div className="w-full p-3 flex items-center gap-3 bg-bgInput-100 rounded-lg mt-2">
                            <img src="/exclamacao-blue.png" alt="Logo pix" />
                            <span className="text-blue-200 text-sm">
                                Envie o PIX utilizando o mesmo CPF/CNPJ registrado na conta.
                            </span>
                        </div>
                    )}
                    <div className="flex flex-col gap-2 mt-2">

                        <div className="relative flex justify-center items-center my-2 md:my-4">
                            {/* Aplica o blur apenas no QR Code */}
                            <div className={`${showBlur && 'blur-sm'}`}>
                                {type === 'comprar' ?
                                    <QRCode value={transactionDataStorage?.pix_qr_code || ''} size={230} /> :
                                    <QRCode value={transactionDataStorage?.deposit_address || ''} size={230} />
                                }
                            </div>

                            {showBlur && (
                                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center p-2 rounded-lg bg-zinc-850">
                                    <span className="text-lg text-white z-50">
                                        O QR Code expirou. aguarde enquanto processamos a transação.
                                    </span>
                                </div>
                            )}
                        </div>

                        <div className="relative w-full p-3 flex flex-col gap-1 bg-bgInput-100 rounded-lg mt-2">
                            <span className="text-blue-200 text-sm">
                                Endereço {type === 'comprar' ? 'do pix' : 'da carteira'}
                            </span>
                            <span className="text-blue-200 text-sm break-words pr-16">
                                {type === 'comprar' ? transactionDataStorage?.pix_qr_code : transactionDataStorage?.deposit_address}
                            </span>
                            <CopyToClipboard
                                text={String(transactionDataStorage?.pix_qr_code)}
                                onCopy={() => handleCopy("Endereço USDT")}

                            >
                                <span className="absolute top-1/2 -translate-y-1/2 right-4 text-yellow-500 font-bold cursor-pointer">Cópia</span>
                            </CopyToClipboard>
                        </div>

                        <div className="relative w-full p-3 flex flex-col gap-1 bg-bgInput-100 rounded-lg mt-2">
                            <span className="text-blue-200 text-sm">Montante total</span>
                            <span className="text-blue-200 font-bold text-sm">{transactionDataStorage?.crypto_amount}</span>
                            <CopyToClipboard text={String(transactionDataStorage?.crypto_amount)} onCopy={() => handleCopy("Montante total")}>
                                <span className="absolute top-1/2 -translate-y-1/2 right-4 text-yellow-500 font-bold cursor-pointer">Cópia</span>
                            </CopyToClipboard>
                        </div>

                        <div className="relative flex items-center justify-center mt-1 md:mt-4">
                            <img
                                src="/img-crypto/Logo Dingo-white.png"
                                alt=""
                                className="absolute top-4 md:top-2 left-4 w-24 md:w-44"
                            />
                            <div className="flex items-center justify-center border-[4px] border-yellow-500 h-20 w-20 md:h-24 md:w-24 rounded-full">
                                <span className="text-yellow-500 font-bold">
                                    {`${Math.floor(time / 60)}:${(time % 60).toString().padStart(2, '0')}`}
                                </span>
                            </div>
                        </div>

                        <div className="w-full flex justify-end">
                            <div className="w-24">
                                <Button
                                    isLoading={false}
                                    text="Voltar"
                                    onClick={() => {
                                        handleShowPayment();
                                        sessionStorage.removeItem('remainingTime');
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {copyStatus && (
                    <Toast
                        img="/check-yellow.png"
                        alt="Imagem de check"
                        text="Texto copiado."
                    />
                )}
            </div>
        </>
    );
}
