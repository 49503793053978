import { useContext, useEffect, useState } from "react";
import { Button } from "./button";
import { InputSingle } from "./input";
import { api } from "../services/axios";
import { UserProps } from "../types/userProps";
import { Context } from "./context/contextProvider";
import { toast, ToastContainer } from "react-toastify";
import { ArrowRightLeft } from "lucide-react";
// import InputMask from 'react-input-mask';

interface ComponentDetailsProps {
    img: string;
    descRede: string;
    type: string | undefined;
    network: string | null;
    networkName: string;
    handleShowDetails: () => void;
    handleShowPayment: () => void;
};

export function ComponentDetails({
    img,
    descRede,
    type,
    network,
    networkName,
    handleShowDetails,
    handleShowPayment,
}: ComponentDetailsProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [firstInputEntered, setFirstInputEntered] = useState('');
    const [walletOrPix, setWalletOrPix] = useState('');
    const [phone, setPhone] = useState('');
    const [quantityCrypto, setQuantityCrypto] = useState('');
    const [quantityBRL, setQuantityBRL] = useState('');
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const dataUser: UserProps = JSON.parse(localStorage.getItem('@dataUserCrypto') ?? '{}');
    const context = useContext(Context);

    useEffect(() => {
        setPhone(dataUser?.user_phone);
    }, [dataUser?.user_phone]);

    useEffect(() => {
        switch (type) {
            case 'comprar':
                setFirstInputEntered('quantityBRL');
                break;
            case 'vender':
                setFirstInputEntered('quantityCrypto');
                break;
        }
    }, [type]);

    useEffect(() => {
        if (typingTimeout) clearTimeout(typingTimeout);

        if (firstInputEntered === 'quantityCrypto') {
            const timeoutId = setTimeout(() => {
                if (quantityCrypto === '') {
                    setQuantityBRL('');
                    setQuantityCrypto('');
                    return;
                }
                const numericValue = quantityCrypto.replace(/\D/g, '');
                fetchCryptoToBRL(numericValue);
            }, 300);
            setTypingTimeout(timeoutId);
        } else if (firstInputEntered === 'quantityBRL') {
            console.log(quantityBRL);
            if (quantityBRL === 'R$ 0,00') {
                setQuantityBRL('');
                setQuantityCrypto('');
                return;
            }
            const timeoutId = setTimeout(() => {
                fetchBRLToCrypto(quantityBRL);
            }, 300);
            setTypingTimeout(timeoutId);
        }

        // Limpeza do timeout ao desmontar ou ao mudar o valor do input
        return () => {
            if (typingTimeout) clearTimeout(typingTimeout);
        };
    }, [firstInputEntered, quantityCrypto, quantityBRL]);

    // Função para formatar o número em BRL com separador de milhar e decimal
    const formatToBRL = (value: string) => {
        const cleanedValue = value.replace(/\D/g, ''); // Remove caracteres não numéricos
        const numberValue = parseFloat(cleanedValue) / 100; // Divide por 100 para ajustar as casas decimais

        return numberValue.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
    };

    const formatToNumber = (value: string): number => {
        // Remover qualquer caractere que não seja número ou ponto
        const numericValue = value.replace(/[^0-9,]/g, '').replace(',', '.');

        // Converter para número
        return parseFloat(numericValue);
    };

    // Atualizar o valor de BRL com formatação enquanto digita
    const handleBRLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        // Remover qualquer caractere não numérico
        const numericValue = value.replace(/\D/g, '');

        // Formatar o valor como BRL
        const formattedValue = formatToBRL(numericValue);

        // Atualizar o estado com o valor formatado
        setQuantityBRL(formattedValue);
    };

    // Função para converter Crypto para BRL
    const fetchCryptoToBRL = async (cryptoAmount: string) => {
        try {
            let coin = network?.split('-')[0].toUpperCase();
            if (network === 'TRX-USDT') {
                coin = network?.split('-')[1].toUpperCase();
            }
            let amountType = 'CRYPTO';
            let side;
            switch (type) {
                case 'comprar':
                    side = 'buy';
                    break;
                case 'vender':
                    side = 'sell';
                    break;
                default:
                    side = 'buy';
            }
            const response = await api.post(`/v1/convertCurrency`, {
                amount: cryptoAmount,
                amount_type: amountType,
                coin,
                side,
                userDocument: dataUser?.user_document,
            });
            const value = Number(response.data.converted_amount).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            });
            setQuantityBRL(value);
        } catch (error) {
            console.log(error);
        }
    };

    // Função para converter BRL para Crypto
    const fetchBRLToCrypto = async (brlAmount: string) => {
        try {
            const coin = network?.split('-')[0].toUpperCase();
            let amountType = 'BRL';

            // Converter o valor para número
            const amount = formatToNumber(brlAmount);
            let side;
            switch (type) {
                case 'comprar':
                    side = 'buy';
                    break;
                case 'vender':
                    side = 'sell';
                    break;
                default:
                    side = 'buy';
            }
            const response = await api.post(`/v1/convertCurrency`, {
                amount: amount.toFixed(2), // Enviar valor como número com duas casas decimais
                amount_type: amountType,
                coin,
                side,
                userDocument: dataUser?.user_document,
            });

            setQuantityCrypto(parseFloat(response.data.converted_amount).toFixed(2));
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmitDetails = () => {
        handleShowPayment();
    };

    if (!context) {
        return null;
    };

    // const { handleTransaction } = context;

    const handleInitiatePurchaseOrSale = async () => {
        if (walletOrPix === '') {
            return toast.warn('Preencha todos os campos!')
        }
        let route;
        let amountType;
        let netWorkSplit = '';
        let rede = '';
        const phoneFormatted = phone.replace(/\D/g, '');
        if (network) {
            netWorkSplit = network.split('-')[0];
            switch (network) {
                case 'USDT-TRX':
                    rede = network.split('-')[1].toLowerCase();
                    break;
                case 'USDT-ETH':
                    rede = network.split('-')[1];
                    break;
                default:
                    rede = network.split('-')[0];
            }
        }

        // Remover caracteres não numéricos e garantir formato numérico adequado para o valor BRL
        const quantityFormattedBRL = quantityBRL.replace(/[^\d,]/g, '').replace(',', '.');
        const quantityFormattedCrypto = quantityCrypto.replace(/[^\d,]/g, '').replace(',', '.');

        let data: any = {
            userDocument: dataUser?.user_document,
            coin: netWorkSplit.toUpperCase(),
            phone: phoneFormatted,
            network: rede,
            networkName: networkName,
        };

        switch (type) {
            case 'comprar':
                route = '/v1/initiatePurchase';
                amountType = 'BRL';
                data = {
                    ...data,
                    amountType: amountType,
                    address: walletOrPix,
                    amount: parseFloat(quantityFormattedBRL),
                };
                break;
            case 'vender':
                route = '/v1/initiateSale';
                amountType = 'CRYPTO';
                data = {
                    ...data,
                    amountType: amountType,
                    pixKey: walletOrPix,
                    amount: parseFloat(quantityFormattedCrypto),
                };
                break;
            default:
                route = '';
                amountType = '';
        }
        setIsLoading(true);
        try {
            const response = await api.post(route, data);
            if (response.data) {
                const formattDate = {
                    ...response.data,
                    network,
                    networkName,
                    phone,
                };
                const formattedDateSale = {
                    ...response.data,
                    phone,
                    pixKey: walletOrPix,
                }
                if (type === 'vender') {
                    localStorage.setItem('@transactionData', JSON.stringify(formattedDateSale));
                    // handleTransaction(formattedDateSale);
                } else {
                    localStorage.setItem('@transactionData', JSON.stringify(formattDate));
                    // handleTransaction(formattDate);
                }
                handleSubmitDetails();
            } else {
                return
            }
        } catch (error: any) {
            toast.error(error.response.data.detail);
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInversionInput = () => {
        switch (firstInputEntered) {
            case 'quantityBRL':
                setFirstInputEntered('quantityCrypto');
                setQuantityBRL('');
                setQuantityCrypto('');
                break;
            case 'quantityCrypto':
                setFirstInputEntered('quantityBRL');
                setQuantityBRL('');
                setQuantityCrypto('');
                break;
        }
    }

    return (
        <div className="w-full max-w-[614px] flex flex-col gap-3 bg-white rounded-xl mt-2 py-8 px-4 md:px-14 z-10">
            <ToastContainer theme="dark" />
            <h2 className="text-textBlack-100">
                {type === 'comprar' ? 'Você está comprando' : 'Você está vendendo'}
            </h2>

            <div className="w-full flex items-center p-3 rounded-lg bg-yellow-600">
                <div className="flex items-center gap-2">
                    <img
                        src={img}
                        alt={`Imagem ${network}`}
                    />
                    <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                            <span className="text-slate-100/80">{network?.toUpperCase()}</span>
                        </div>
                        <span className="text-slate-100/80">{descRede}</span>
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-1 mt-4">
                <InputSingle
                    type="text"
                    label={type === 'comprar' ? 'Sua wallet' : 'Seu pix'}
                    placeholder={type === 'comprar' ? 'Digite a chave da sua wallet' : 'Digite a sua chave Pix'}
                    gray
                    textLabel="text-textInput-100"
                    value={walletOrPix}
                    onChange={(e) => setWalletOrPix(e.target.value)}
                />
                <span className="text-yellow-500 mt-2">
                    {type === 'vender' && 'Quanto você vai enviar'}
                </span>
                <div className={`relative flex flex-col ${firstInputEntered === 'quantityCrypto' ? 'flex-col-reverse' : 'flex-col'}`}>
                    <div className="w-full relative flex items-center gap-1">
                        <InputSingle
                            type="text"
                            rounded="rounded-3xl"
                            styleComp="pr-16"
                            value={quantityBRL}
                            onChange={handleBRLChange} // Formatação para BRL
                        />

                        <div className="absolute top-0 right-0 h-[50px] flex items-center justify-center bg-bgInput-100 text-zinc-400 w-14 rounded-r-3xl">
                            <span>R$</span>
                        </div>
                    </div>
                    <div onClick={handleInversionInput} className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 -mt-2 cursor-pointer z-50">
                        <button className="p-1 bg-bgInput-100 shadow rounded-full">
                            <ArrowRightLeft className="text-yellow-500" size={30} />
                        </button>
                    </div>
                    <div className="w-full relative flex items-center gap-1">
                        <InputSingle
                            type="text"
                            rounded="rounded-3xl"
                            styleComp="pr-16"
                            value={quantityCrypto}
                            onChange={(e) => setQuantityCrypto(e.target.value)}
                        />

                        <div className="absolute top-0 right-0 h-[50px] flex items-center justify-center bg-bgInput-100 text-zinc-400 w-14 rounded-r-3xl">
                            <span>{network?.toUpperCase().split('-')[0]}</span>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-6">
                    <Button
                        isLoading={isLoading}
                        text="Concluir"
                        onClick={handleInitiatePurchaseOrSale}
                    />
                    <Button
                        isLoading={false}
                        text="Voltar"
                        styleComp={`bg-transparent text-textBlack-100`}
                        onClick={handleShowDetails}
                    />
                </div>
            </div>
        </div>
    )
}
