import { useEffect, useState } from "react";
import { InputSingle } from "../components/input";
import { Navbar } from "./components/navbar";
import { UserProps } from "../types/userProps";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast, ToastContainer } from "react-toastify";
import { Copy } from "lucide-react";
import { useLocation } from "react-router-dom";

export function Link() {
    const [link, setLink] = useState('');
    const [showMenu, setShowMenu] = useState(true);
    const location = useLocation();
    const getDataUser: UserProps = JSON.parse(localStorage.getItem('@dataAffiliateCrypto') ?? '{}');

    useEffect(() => {
        if (getDataUser) {
            if (getDataUser?.affiliate_code) {
                const newLocation = `${window.location.origin}${location.pathname.split('/affiliate')[0]}/register/${getDataUser?.affiliate_code}`
                setLink(newLocation);
            }
        }
    }, [getDataUser, location.pathname]);

    const handleOnCopy = () => {
        toast.success('Link copiado com sucesso.');
    }

    const handleShowMenu = () => {
        setShowMenu(!showMenu);
    }

    return (
        <div className="relative w-full h-screen flex overflow-hidden bg-zinc-700">
            <ToastContainer theme="dark" />
            <Navbar
                inicio={false}
                searUser={false}
                settings={false}
                documents={false}
                withdraw={false}
                links
                handleShowMenu={handleShowMenu}
                showMenu={showMenu}
            />

            <div className="w-full h-full p-6">
                <h2 className="text-slate-200 text-2xl mt-14 md:mt-0">Links de afiliados</h2>

                <div className="flex flex-col w-full max-w-xl gap-3 mt-4 md:mt-8">
                    <CopyToClipboard text={link} onCopy={handleOnCopy}>
                        <div className="relative">
                            <InputSingle
                                disabled
                                type="text"
                                label="Link de afiliado"
                                placeholder={`${window.location.origin}${location.pathname.split('/affiliate')[0]}/register/${getDataUser?.affiliate_code}`}
                                styleComp="bg-white pr-10"
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                            />
                            <button className="absolute top-1/2 -translate-y-1/2 right-4 mt-[6px]">
                                <Copy className="text-yellow-500" size={20} />
                            </button>
                        </div>
                    </CopyToClipboard>
                </div>
            </div>
        </div>
    )
}