import { Button } from "./components/button";
import { EllipseBackground } from "./components/ellipse_background";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
// import { Payment } from "./components/payment";
import { ComponentMetodo } from "./components/componentMetodo";
import { ComponentDetails } from "./components/componentDetails";
import { PaymentQrcode } from "./components/payment_qrcode";
import { api } from "./services/axios";
import { UserProps } from "./types/userProps";

export function TransactionType() {
    const [showDeteails, setshowDeteails] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [imgSelected, setImgSelected] = useState('');
    const [descMoedaSelected, setdescMoedaSelected] = useState('');
    const [network, setNetwork] = useState<string | null>(null);
    const [networkName, setNetworkName] = useState('');
    const [valueBtc, setValueBtc] = useState<number | null>(null);
    const [valueUsdt, setValueUsdt] = useState<number | null>(null);
    const [valueUsdtTrx, setValueUsdtTrx] = useState<number | null>(null);
    const [valueTrx, setValueTrx] = useState<number | null>(null);
    const [valueEth, setValueEth] = useState<number | null>(null);
    const [valueLtc, setValueLtc] = useState<number | null>(null);
    const [valueDoge, setValueDoge] = useState<number | null>(null);
    const [width, setWidth] = useState(window.innerWidth);
    const coins = ["USDT-TRX", "USDT", "TRX", "BTC", "ETH", "LTC", "DOGE"];
    const getUserStorage: UserProps = JSON.parse(localStorage.getItem('@dataUserCrypto') ?? '{}');
    const { type } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        // Adiciona o evento de redimensionamento
        window.addEventListener('resize', handleResize);

        // Remove o evento de redimensionamento ao desmontar o componente
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const getPricesCrypto = async () => {
            try {
                if (getUserStorage && getUserStorage.user_document) {
                    let side;
                    switch (type) {
                        case 'comprar':
                            side = 'buy';
                            break;
                        case 'vender':
                            side = 'sell';
                            break;
                        default:
                            side = 'buy';
                    }
                    for (let i = 0; i < coins.length; i++) {
                        let formattedSplitCoins = coins[i]
                        if(coins[i] === 'USDT-TRX') {
                            formattedSplitCoins = coins[i].split('-')[0];
                        }
                        const response = await api.post('/v1/convertCurrency', {
                            amount: 1,
                            amount_type: 'CRYPTO',
                            coin: formattedSplitCoins,
                            side,
                            userDocument: getUserStorage.user_document,
                        });

                        if (response.data) {
                            switch (coins[i]) {
                                case 'USDT':
                                    setValueUsdt(response.data.converted_amount);
                                    break;
                                case 'USDT-TRX':
                                    setValueUsdtTrx(response.data.converted_amount);
                                    break;
                                case 'TRX':
                                    setValueTrx(response.data.converted_amount);
                                    break;
                                case 'ETH':
                                    setValueEth(response.data.converted_amount);
                                    break;
                                case 'BTC':
                                    setValueBtc(response.data.converted_amount);
                                    break;
                                case 'LTC':
                                    setValueLtc(response.data.converted_amount);
                                    break;
                                case 'DOGE':
                                    setValueDoge(response.data.converted_amount);
                                    break;
                            }
                        }
                    }
                } else {
                    console.log("user_document is missing from localStorage data.");
                }
            } catch (error) {
                console.log(error);
            }
        };
        getPricesCrypto();
    }, [getUserStorage.user_document, type]);

    const handleShowPayment = () => {
        setShowPayment(!showPayment);
    };

    const handleShowDetails = () => {
        setshowDeteails(!showDeteails);
    };

    const handleMoedaClick = (moeda: string) => {
        // Define a moeda como selecionada ou desmarca se já estiver selecionada
        setNetwork(network === moeda ? null : moeda);
    };

    const handleLogout = () => {
        localStorage.removeItem('@dataUserCrypto');
        sessionStorage.removeItem('remainingTime');
        sessionStorage.removeItem('#user-verified');
        navigate('/');
    }

    return (
        <div className={`relative w-full h-screen flex flex-col px-2 md:px-0 md:flex-row md:justify-center md:items-center  ${showPayment && 'overflow-y-hidden'} overflow-x-hidden overflow-y-hidden bg-zinc-800`}>
            <EllipseBackground />
            {showPayment &&
                <PaymentQrcode
                    handleShowPayment={handleShowPayment}
                    descRede={descMoedaSelected}
                    network={network}
                    networkName={networkName}
                />}
            <div className="flex justify-end  mt-4 mr-4 md:mt-0 md:mr-0 md:w-24 md:absolute md:right-6 md:top-4 z-10">
                <div className="w-24">
                    <Button
                        isLoading={false}
                        text="Sair"
                        onClick={handleLogout}
                    />
                </div>
            </div>

            {!showDeteails ? (
                <div className="w-full max-w-[614px] h-full max-h-[800px] overflow-y-auto no-scrollbar z-10 mt-6 md:mt-0 md:mx-0">
                    <div className="flex items-center">
                        <Button
                            text="Comprar"
                            styleComp={type === 'comprar' ? 'bg-yellow-600 rounded-l-[41px] text-white h-[45px] md:h-[70px]' : 'bg-white rounded-l-[41px] text-text-Black-100 h-[45px] md:h-[70px]'}
                            isLoading={false}
                            rounded="rounded-r-0"
                            onClick={() => navigate('/transaction/comprar')}
                        />
                        <Button
                            text="Vender"
                            styleComp={type === 'vender' ? 'bg-red-500 rounded-r-[41px] text-white h-[45px] md:h-[70px]' : 'bg-white rounded-r-[41px] text-text-Black-100 h-[45px] md:h-[70px]'}
                            isLoading={false}
                            rounded="rounded-l-0"
                            onClick={() => navigate('/transaction/vender')}
                        />
                    </div>

                    <div className="w-full flex flex-col gap-3 bg-white rounded-xl mt-2 py-8 px-4 md:px-14">
                        <span className="text-textBlack-100">{type === 'comprar' ? 'Selecione o método de compra' : 'Selecione o método de venda'}</span>

                        <div className="w-full flex flex-col gap-2">
                            <ComponentMetodo
                                img="/img-crypto/usdt-trx.png"
                                name="USDT"
                                rede="TRX"
                                descRede="Theter USD (Tron)"
                                value={valueUsdtTrx}
                                currency="BRL"
                                bgRede="bg-red-600"
                                hover={network === "USDT-TRX"}
                                onClick={() => {
                                    handleMoedaClick("USDT-TRX");
                                    setImgSelected('/img-crypto/usdt-trx.png');
                                    setdescMoedaSelected('Theter USD (Tron)');
                                    setNetworkName('Tron [TRC-20]');
                                    if (width <= 768) {
                                        handleShowDetails();
                                        return;
                                    }
                                }}
                            />
                            <ComponentMetodo
                                img="/img-crypto/usdt-eth.png"
                                name="USDT"
                                rede="ETH"
                                descRede="Theter USD (Ethereum)"
                                value={valueUsdt}
                                currency="BRL"
                                bgRede="bg-blue-300"
                                hover={network === "USDT-ETH"}
                                onClick={() => {
                                    handleMoedaClick("USDT-ETH");
                                    setImgSelected('/img-crypto/usdt-eth.png');
                                    setdescMoedaSelected('Theter USD (Ethereum)');
                                    setNetworkName('Ethereum [ERC-20]');
                                    if (width <= 768) {
                                        handleShowDetails();
                                        return;
                                    }
                                }}
                            />
                            <ComponentMetodo
                                img="/img-crypto/tron-trx.png"
                                name="TRX"
                                descRede="Tron"
                                value={valueTrx}
                                currency="BRL"
                                bgRede="bg-red-600"
                                hover={network === "TRX"}
                                onClick={() => {
                                    handleMoedaClick("TRX");
                                    setImgSelected('/img-crypto/tron-trx.png');
                                    setdescMoedaSelected('Tron');
                                    setNetworkName('Tron [TRC-20]');
                                    if (width <= 768) {
                                        handleShowDetails();
                                        return;
                                    }
                                }}
                            />
                            <ComponentMetodo
                                img="/img-crypto/bitcoin.png"
                                name="BTC"
                                descRede="Bitcoin"
                                value={valueBtc}
                                currency="BRL"
                                hover={network === "btc"}
                                onClick={() => {
                                    handleMoedaClick("btc");
                                    setImgSelected('/img-crypto/bitcoin.png');
                                    setdescMoedaSelected('Bitcoin');
                                    setNetworkName('Bitcoin');
                                    if (width <= 768) {
                                        handleShowDetails();
                                        return;
                                    }
                                }}

                            />
                            <ComponentMetodo
                                img="/img-crypto/eth.png"
                                name="ETH"
                                descRede="Ethereum"
                                value={valueEth}
                                currency="BRL"
                                hover={network === "eth"}
                                onClick={() => {
                                    handleMoedaClick("eth");
                                    setImgSelected('/img-crypto/eth.png');
                                    setdescMoedaSelected('Ethereum [ERC-20]');
                                    setNetworkName('Ethereum [ERC-20]');
                                    if (width <= 768) {
                                        handleShowDetails();
                                        return;
                                    }
                                }}
                            />
                            <ComponentMetodo
                                img="/img-crypto/ltc.png"
                                name="LTC"
                                descRede="Litecoin"
                                value={valueLtc}
                                currency="BRL"
                                hover={network === "LTC"}
                                onClick={() => {
                                    handleMoedaClick("LTC");
                                    setImgSelected('/img-crypto/ltc.png');
                                    setdescMoedaSelected('Litecoin');
                                    setNetworkName('Litecoin');
                                    if (width <= 768) {
                                        handleShowDetails();
                                        return;
                                    }
                                }}
                            />
                            <ComponentMetodo
                                img="/img-crypto/dodge.png"
                                name="DOGE"
                                descRede="Dogecoin"
                                value={valueDoge}
                                currency="BRL"
                                hover={network === "doge"}
                                onClick={() => {
                                    handleMoedaClick("doge");
                                    setImgSelected('/img-crypto/dodge.png');
                                    setdescMoedaSelected('Dogecoin');
                                    setNetworkName('Doge');
                                    if (width <= 768) {
                                        handleShowDetails();
                                        return;
                                    }
                                }}
                            />
                        </div>

                        <div className="hidden md:flex">
                            <Button
                                text="Fazer operação"
                                isLoading={false}
                                onClick={handleShowDetails}
                                styleComp={`${network === null ? 'bg-bgInput-100 text-slate-300' : ''}`}
                                disabled={network === null}
                            />
                        </div>
                    </div>
                </div>
            ) :
                <ComponentDetails
                    img={imgSelected}
                    descRede={descMoedaSelected}
                    type={type}
                    network={network}
                    networkName={networkName}
                    handleShowDetails={handleShowDetails}
                    handleShowPayment={handleShowPayment}
                />}
        </div>
    );
}
