import React, { useState } from 'react';
import { Button } from './components/button';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Input, InputFormMask } from './components/input';
import { EllipseBackground } from './components/ellipse_background';
import { api } from './services/axios';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

const formSchema = z.object({
  name: z.string(),
  document_number: z.string(),
  birthday: z.string(),
  email: z.string().email({ message: 'E-mail inválido' }),
  phone: z.string(),
  pix_key: z.string(),
  // affiliate_code: z.string().optional().nullable(),
})

const Register: React.FC = () => {
  // const [isCheckedCodeAffiliate, setIsCheckedCodeAffiliate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { codeAffiliate } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    console.log()
    setIsLoading(true)
    try {
      const phoneFormatted = values.phone.replace(/\D/g, '');
      const cpfOrCnpjFormatted = values.document_number.replace(/\D/g, '');
      const pixKeyFormatted = values.pix_key.replace(/\D/g, '');
      let data: any = {
        name: values.name,
        document_number: cpfOrCnpjFormatted,
        birthday: values.birthday,
        phone: phoneFormatted,
        email: values.email,
        pix_key: pixKeyFormatted,
      }
      if (codeAffiliate) {
        data = {
          ...data,
          affiliate_code: codeAffiliate,
        }
      }
      const response = await api.post('/v1/createUser', data);
      if (response?.data) {
        toast.warn(response.data.message);
        if (response.data.success === false) {
          return;
        } else {
          toast.success('Usuário criado com sucesso.');
          navigate('/');
        }
      }
    } catch (error: any) {
      if (error?.response?.data?.detail) {
        error.response.data.detail.map((error: any) => {
          return toast.warn(error.msg);
        });
      } else {
        console.log(error.response.data.message);
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  // const handleCheckboxChangeCodeAffiliate = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsCheckedCodeAffiliate(event.target.checked);
  // };

  return (
    <div className="relative w-full h-screen bg-zinc-800 flex justify-center items-center overflow-hidden px-2 md:px-0">
      {/* Ellipse Background */}
      <EllipseBackground />
      <ToastContainer theme="dark" />

      {/* Group 46 */}
      <div className="relative w-full max-w-[511px] flex justify-center items-center">
        {/* Rectangle 8 */}
        <div className="absolute w-full h-full bg-white shadow-[0px_4px_100px_rgba(0,0,0,0.1)] rounded-xl z-10"></div>

        {/* Frame 85 */}
        <div className="relative w-full px-10 py-6 flex flex-col justify-center gap-3 z-20">
          {/* Frame 1: Welcome Section */}
          <div className="flex flex-col gap-1">
            <div className="text-3xl font-bold text-[#161923]">Seja bem-vindo!</div>
            <div className="text-zinc-500">Por favor, preencha os campos para registrar.</div>
          </div>

          {/* Form Section */}
          <form
            className="flex flex-col"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* E-mail Input */}
            <div className="flex flex-col gap-1">
              <label className="text-zinc-400 text-[16px]">Nome</label>
              <Input
                type='text'
                name='name'
                placeholder='Digite seu nome'
                register={register}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-zinc-400 text-[16px]">CPF ou CNPJ</label>
              <InputFormMask
                type='text'
                name='document_number'
                placeholder='Digite seu CPF ou CNPJ'
                register={register}
                mask='999.999.999-99'
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-zinc-400 text-[16px]">Data de nascimento</label>
              <InputFormMask
                type='text'
                name='birthday'
                placeholder='Digite sua data de aniversário'
                register={register}
                mask='99/99/9999'
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-zinc-400 text-[16px]">Telefone</label>
              <InputFormMask
                mask="(99) 99999-9999"
                type='text'
                name='phone'
                placeholder='Digite seu telefone'
                register={register}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-zinc-400 text-[16px]">E-mail</label>
              <Input
                type='text'
                name='email'
                placeholder='Digite seu e-mail'
                register={register}
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-zinc-400 text-[16px]">Chave pix</label>
              <Input
                type='text'
                name='pix_key'
                placeholder='Digite sua chave pix'
                register={register}
                error={errors?.pix_key?.message}
              />
            </div>

            {/* <div className="flex items-center gap-2 mb-4">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  id="affiliate"
                  className="absolute w-5 h-5 opacity-0 z-10 peer"
                  checked={isCheckedCodeAffiliate}
                  onChange={handleCheckboxChangeCodeAffiliate}
                />
                <span
                  className={`w-5 h-5 inline-block rounded-md border-2 border-gray-300 peer-checked:bg-yellow-500 bg-gray-100`}
                ></span>
                <label
                  htmlFor="affiliate"
                  className="ml-2 text-zinc-400 cursor-pointer z-10"
                >
                  Código afiliado
                </label>
              </label>
            </div> */}

            {/* {isCheckedCodeAffiliate && (
              <div className="flex flex-col gap-1">
                <label className="text-zinc-400 text-[16px]">Código do afiliado</label>
                <Input
                  type='text'
                  name='affiliate_code'
                  placeholder='Digite o código do afiliado'
                  register={register}
                  error={errors?.affiliate_code?.message}
                />
              </div>
            )} */}

            {/* Button */}
            <Button
              type='submit'
              text='Adicionar usuário'
              isLoading={isLoading}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
