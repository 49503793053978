import React, { ReactNode } from 'react';
import InputMask from 'react-input-mask';
import { UseFormRegister } from 'react-hook-form';

interface InputProps {
    type: string;
    label?: string;
    placeholder: string;
    name: string;
    register: UseFormRegister<any>;
    borderColor?: string;
    gray?: boolean;
    error?: string;
}
interface InputSingleProps {
    disabled?: boolean;
    type: string;
    label?: string;
    placeholder?: string;
    styleComp?: string;
    borderColor?: string;
    gray?: boolean;
    textLabel?: string;
    rounded?: string;
    h?: string;
    textSize?: string;
    textColor?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
}

interface InputFormMaskProps {
    type?: string,
    register: any;
    name: string;
    placeholder: string;
    label?: string;
    icon?: ReactNode;
    mask: string;
    iconPlace?: boolean;
    h?: string;
    gray?: boolean;
    styleComp?: string;
    borderColor?: string;
    textColor?: string;
    rounded?: string;
}

export const Input: React.FC<InputProps> = ({
    type,
    label,
    placeholder,
    name,
    register,
    gray,
    error,
    borderColor,
}) => {
    return (
        <div className="w-full mb-4">
            {label && <label className="text-[14px] font-normal text-[#aaaaaa]">{label}</label>}
            <input
                type={type}
                placeholder={placeholder}
                {...register(name)}
                className={`w-full h-[50px] px-6 ${gray ? 'bg-bgInput-100' : 'bg-[#ffffff33]'} border ${borderColor !== '' ? `border ${borderColor}` : ''} rounded-lg text-[16px] text-zinc-500 outline-none`}
            />
            {/* Exibir mensagem de erro se houver */}
            {error && <span className="text-red-500 text-sm">{error}</span>}
        </div>
    );
};

export const InputSingle: React.FC<InputSingleProps> = ({
    disabled,
    type,
    label,
    placeholder,
    styleComp,
    borderColor,
    gray,
    textLabel,
    rounded,
    h,
    textSize,
    textColor,
    value,
    onChange,
    onBlur,
}) => {
    return (
        <div className="w-full mb-4 flex flex-col gap-1">
            {label && <label className={`${textLabel || 'text-zinc-300'}`}>{label}</label>}
            <input
                disabled={disabled}
                type={type}
                placeholder={placeholder}
                className={`w-full ${h || 'h-[50px]'} px-2 md:px-6 ${gray ? 'bg-bgInput-100' : 'bg-[#ffffff33]'} ${borderColor !== '' ? `border ${borderColor}` : ''} ${rounded || 'rounded-lg'} ${textSize || 'text-[16px]'} ${textColor || 'text-zinc-500'} outline-none ${styleComp}`}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
        </div>
    );
};

export const InputFormMask = ({
    type,
    register,
    name,
    placeholder,
    label,
    icon,
    mask,
    h,
    gray,
    borderColor,
    textColor,
    rounded,
    styleComp,
}: InputFormMaskProps) => {
    return (
        <div className="input-group">
            <label>{label}</label>
            <div className="input-wrapper">
                <InputMask
                    type={type}
                    mask={mask}
                    {...register(name)}
                    className={`w-full ${h || 'h-[50px]'} px-6 md:px-6 ${gray ? 'bg-bgInput-100' : 'bg-[#ffffff33]'} ${borderColor !== '' ? `border ${borderColor}` : ''} ${rounded || 'rounded-lg'} text-[16px] ${textColor || 'text-zinc-500'} outline-none ${styleComp} mb-4`}
                    placeholder={placeholder}
                >

                </InputMask>
                {icon && <div className="input-icon">{icon}</div>}
            </div>
        </div>
    );
};