import axios from "axios";

const baseURL = "https://caktocrypto-production.up.railway.app/api";
// process.env.REACT_APP_HOST

export const api = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json'
    }
});